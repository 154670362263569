@font-face {
    font-family: 'Satoshi-Variable';
    font-weight: 300 900;
    font-display: swap;
    font-style: normal;
    src: url('../assets/fonts/Satoshi-Variable.woff2') format('woff2');

}


@font-face {
    font-family: 'Satoshi-VariableItalic';
    font-weight: 300 900;
    font-display: swap;
    font-style: italic;
    src: url('../assets/fonts/Satoshi-VariableItalic.woff2') format('woff2');
}


@font-face {
    font-family: 'Satoshi-Light';
    src: url('../assets/fonts/Satoshi-Light.woff2') format('woff2');

    font-weight: 300;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi-LightItalic';
    src: url('../assets/fonts/Satoshi-LightItalic.woff2') format('woff2');

    font-weight: 300;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi-Regular';
    src: url('../assets/fonts/Satoshi-Regular.woff2') format('woff2');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi-Italic';
    src: url('../assets/fonts/Satoshi-Italic.woff2') format('woff2');

    font-weight: 400;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi-Medium';
    src: url('../assets/fonts/Satoshi-Medium.woff2') format('woff2');

    font-weight: 500;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi-MediumItalic';
    src: url('../assets/fonts/Satoshi-MediumItalic.woff2') format('woff2');

    font-weight: 500;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi-Bold';
    src: url('../assets/fonts/Satoshi-Bold.woff2') format('woff2');

    font-weight: 700;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi-BoldItalic';
    src: url('../assets/fonts/Satoshi-BoldItalic.woff2') format('woff2');
    font-weight: 700;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi-Black';
    src: url('../assets/fonts/Satoshi-Black.woff2') format('woff2');
    font-weight: 900;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi-BlackItalic';
    src: url('../assets/fonts/Satoshi-BlackItalic.woff2') format('woff2');
    font-weight: 900;
    font-display: swap;
    font-style: italic;
}

@font-face {
  font-family: 'GeneralSans';
  font-style: italic;
  font-weight: bold;
  src: url('/assets/fonts/GeneralSans-BoldItalic.eot'); /* IE9 */
  src: url('/assets/fonts/GeneralSans-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/GeneralSans-BoldItalic.woff2') format('woff2'), /* Super modern browsers */
       url('/assets/fonts/GeneralSans-BoldItalic.woff') format('woff'), /* Modern browsers */
       url('/assets/fonts/GeneralSans-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/GeneralSans-BoldItalic.otf') format('opentype'); /* Legacy fonts */
}

@font-face {
    font-family: 'GeneralSans';
    font-weight: bold;
    font-style: normal;
    src: url('/assets/fonts/GeneralSans-Bold.eot'); /* IE9 */
    src: url('/assets/fonts/GeneralSans-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/GeneralSans-Bold.woff2') format('woff2'), /* Modern browsers */
         url('/assets/fonts/GeneralSans-Bold.woff') format('woff'), /* More modern browsers */
         url('/assets/fonts/GeneralSans-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/GeneralSans-Bold.otf') format('opentype'); /* Legacy */
  }
  

  @font-face {
    font-family: 'GeneralSans';
    font-weight: bold;
    font-style: italic;
    src: url('/assets/fonts/GeneralSans-BoldItalic.eot'); /* IE9 */
    src: url('/assets/fonts/GeneralSans-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/GeneralSans-BoldItalic.woff2') format('woff2'), /* Modern browsers */
         url('/assets/fonts/GeneralSans-BoldItalic.woff') format('woff'), /* More modern browsers */
         url('/assets/fonts/GeneralSans-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/GeneralSans-BoldItalic.otf') format('opentype'); /* Legacy */
  }
  
  // ExtraLight
@font-face {
    font-family: 'GeneralSans';
    font-weight: 200;
    font-style: normal;
    src: url('/assets/fonts/GeneralSans-Extralight.eot');
    src: url('/assets/fonts/GeneralSans-Extralight.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/GeneralSans-Extralight.woff2') format('woff2'),
         url('/assets/fonts/GeneralSans-Extralight.woff') format('woff'),
         url('/assets/fonts/GeneralSans-Extralight.ttf') format('truetype'),
         url('/assets/fonts/GeneralSans-Extralight.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'GeneralSans';
    font-weight: 200;
    font-style: italic;
    src: url('/assets/fonts/GeneralSans-ExtralightItalic.eot');
    src: url('/assets/fonts/GeneralSans-ExtralightItalic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/GeneralSans-ExtralightItalic.woff2') format('woff2'),
         url('/assets/fonts/GeneralSans-ExtralightItalic.woff') format('woff'),
         url('/assets/fonts/GeneralSans-ExtralightItalic.ttf') format('truetype'),
         url('/assets/fonts/GeneralSans-ExtralightItalic.otf') format('opentype');
  }
  
  // Light
  @font-face {
    font-family: 'GeneralSans';
    font-weight: 300;
    font-style: normal;
    src: url('/assets/fonts/GeneralSans-Light.eot');
    src: url('/assets/fonts/GeneralSans-Light.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/GeneralSans-Light.woff2') format('woff2'),
         url('/assets/fonts/GeneralSans-Light.woff') format('woff'),
         url('/assets/fonts/GeneralSans-Light.ttf') format('truetype'),
         url('/assets/fonts/GeneralSans-Light.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'GeneralSans';
    font-weight: 300;
    font-style: italic;
    src: url('/assets/fonts/GeneralSans-LightItalic.eot');
    src: url('/assets/fonts/GeneralSans-LightItalic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/GeneralSans-LightItalic.woff2') format('woff2'),
         url('/assets/fonts/GeneralSans-LightItalic.woff') format('woff'),
         url('/assets/fonts/GeneralSans-LightItalic.ttf') format('truetype'),
         url('/assets/fonts/GeneralSans-LightItalic.otf') format('opentype');
  }
  
  // Regular
  @font-face {
    font-family: 'GeneralSans';
    font-weight: 400;
    font-style: normal;
    src: url('/assets/fonts/GeneralSans-Regular.eot');
    src: url('/assets/fonts/GeneralSans-Regular.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/GeneralSans-Regular.woff2') format('woff2'),
         url('/assets/fonts/GeneralSans-Regular.woff') format('woff'),
         url('/assets/fonts/GeneralSans-Regular.ttf') format('truetype'),
         url('/assets/fonts/GeneralSans-Regular.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'GeneralSans';
    font-weight: 400;
    font-style: italic;
    src: url('/assets/fonts/GeneralSans-Italic.eot');
    src: url('/assets/fonts/GeneralSans-Italic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/GeneralSans-Italic.woff2') format('woff2'),
         url('/assets/fonts/GeneralSans-Italic.woff') format('woff'),
         url('/assets/fonts/GeneralSans-Italic.ttf') format('truetype'),
         url('/assets/fonts/GeneralSans-Italic.otf') format('opentype');
  }
  
  // Medium
  @font-face {
    font-family: 'GeneralSans';
    font-weight: 500;
    font-style: normal;
    src: url('/assets/fonts/GeneralSans-Medium.eot');
    src: url('/assets/fonts/GeneralSans-Medium.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/GeneralSans-Medium.woff2') format('woff2'),
         url('/assets/fonts/GeneralSans-Medium.woff') format('woff'),
         url('/assets/fonts/GeneralSans-Medium.ttf') format('truetype'),
         url('/assets/fonts/GeneralSans-Medium.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'GeneralSans';
    font-weight: 500;
    font-style: italic;
    src: url('/assets/fonts/GeneralSans-MediumItalic.eot');
    src: url('/assets/fonts/GeneralSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/GeneralSans-MediumItalic.woff2') format('woff2'),
         url('/assets/fonts/GeneralSans-MediumItalic.woff') format('woff'),
         url('/assets/fonts/GeneralSans-MediumItalic.ttf') format('truetype'),
         url('/assets/fonts/GeneralSans-MediumItalic.otf') format('opentype');
  }
  
  // Semibold
  @font-face {
    font-family: 'GeneralSans';
    font-weight: 600;
    font-style: normal;
    src: url('/assets/fonts/GeneralSans-Semibold.eot');
    src: url('/assets/fonts/GeneralSans-Semibold.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/GeneralSans-Semibold.woff2') format('woff2'),
         url('/assets/fonts/GeneralSans-Semibold.woff') format('woff'),
         url('/assets/fonts/GeneralSans-Semibold.ttf') format('truetype'),
         url('/assets/fonts/GeneralSans-Semibold.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'GeneralSans';
    font-weight: 600;
    font-style: italic;
    src: url('/assets/fonts/GeneralSans-SemiboldItalic.eot');
    src: url('/assets/fonts/GeneralSans-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/GeneralSans-SemiboldItalic.woff2') format('woff2'),
         url('/assets/fonts/GeneralSans-SemiboldItalic.woff') format('woff'),
         url('/assets/fonts/GeneralSans-SemiboldItalic.ttf') format('truetype'),
         url('/assets/fonts/GeneralSans-SemiboldItalic.otf') format('opentype');
  }
  
  // Variable (Bold)
  @font-face {
    font-family: 'GeneralSans';
    font-weight: 700;
    font-style: normal;
    src: url('/assets/fonts/GeneralSans-Bold.eot');
    src: url('/assets/fonts/GeneralSans-Bold.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/GeneralSans-Bold.woff2') format('woff2'),
         url('/assets/fonts/GeneralSans-Bold.woff') format('woff'),
         url('/assets/fonts/GeneralSans-Bold.ttf') format('truetype'),
         url('/assets/fonts/GeneralSans-Bold.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'GeneralSans';
    font-weight: 700;
    font-style: italic;
    src: url('/assets/fonts/GeneralSans-BoldItalic.eot');
    src: url('/assets/fonts/GeneralSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/GeneralSans-BoldItalic.woff2') format('woff2'),
         url('/assets/fonts/GeneralSans-BoldItalic.woff') format('woff'),
         url('/assets/fonts/GeneralSans-BoldItalic.ttf') format('truetype'),
         url('/assets/fonts/GeneralSans-BoldItalic.otf') format('opentype');
  }
  